.body {
  display: grid;
  flex: 1;
}

.cell {
  align-items: center;
  contain: strict;
  content-visibility: auto;
  display: grid;
  justify-content: stretch;
  overflow: hidden;
  padding: 6px 16px; /* override MUI "last child" rule */
  text-align: inherit;
}

.head {
  display: grid;
  position: sticky;
  text-align: center;
  top: 0;
  z-index: 1;
}

.root {
  background-color: var(--mui-palette-background-paper);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  width: 100%;
  will-change: transform;
}
